<template>
    <input
        :id="id"
        v-model="insideValue"
        v-maska
        data-maska="+7 (###) ###-##-##"
        type="tel"
        :name="name"
        class="input form-control"
        :class="{
            'phone-disabled': readonly || disabled,
            'form-input-error': error,
        }"
        autocomplete="off"
        :placeholder="placeholder"
        :readonly="readonly"
        :disabled="disabled"
        @input="handleInput"
    />
</template>

<script>
import { replaceRussianPhonePrefix } from '@/utils/phone';

export default {
    name: 'PhoneComponent',
    props: {
        id: {
            type: String,
            default: 'phone-number',
        },
        name: {
            type: String,
            default: 'phone-number',
        },
        value: {
            type: null,
            default: null,
        },
        placeholder: {
            type: String,
            default: '+7 (###) ###-##-##',
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        error: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            insideValue: this.value || '',
        };
    },
    watch: {
        value: function (val) {
            if (typeof val === 'string') this.insideValue = replaceRussianPhonePrefix(val, 'tel');
        },
    },
    methods: {
        handleInput(e) {
            let value = e.target.value;
            value = replaceRussianPhonePrefix(value, 'tel');

            this.insideValue = value;
            this.$emit('change', value);
        },
    },
};
</script>

<style lang="scss" scoped>
.custom-placeholder-transition:focus::placeholder {
    transition: opacity 1s;
}
.phone-disabled {
    @apply text-gray-300;

    &:focus {
        &::placeholder {
            @apply text-gray-300;
        }
    }
}
</style>
