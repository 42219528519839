export const managersMixin = {
    data() {
        return {
            loading: false,
            form: {
                first_name: null,
                last_name: null,
                phone: null,
                password: null,
                rop: null,
                bitrix_user_id: null,
            },
            rops: [],
        };
    },
    methods: {
        clearForm() {
            for (const key in this.form) {
                this.form[key] = null;
            }
        },
        async fetchRops() {
            try {
                const { data } = await this.axios.get('/users/', { params: { role: 'rop' } });
                this.rops = data.data;
            } catch (ex) {
                console.log('cant fetch rops: ' + ex);
            }
        },
        async fetchManager() {
            this.loading = true;
            try {
                const { data } = await this.axios.get(`/managers/${this.$route.params.id}/edit`);

                console.log('fetchManager ~ data:', data);
                for (const field in this.form) {
                    this.form[field] = data.manager[field];
                }

                this.loading = false;
            } catch (ex) {
                console.log('cant find manager: ' + ex);
                this.loading = false;
                this.$router.push('/error-page');
            }
        },
    },
};
